export const number = (num: number) => {
  if (num && !isNaN(num)) {
    return Number(num).toLocaleString()
    // return new Intl.NumberFormat('en-US').format(parseInt(num))
  }
  return '0'
}
export const decimal = (num: number) => {
  if (num && !isNaN(num)) {
    return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2 })
  }
  return '0.00'
}