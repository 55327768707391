<script setup lang="ts">
  import { computed } from 'vue'

  const emit = defineEmits<{
    (e: 'update:modelValue', data: boolean): void,
  }>()

  const props = defineProps<{
    modelValue: boolean,
    disabled?: boolean,
  }>()

  const value = computed({
    get: () => props.modelValue,
    set: newValue => {
      emit('update:modelValue', newValue)
    },
  })
  const id = 'checkbox-' + (0 | Math.random() * 9e6).toString(36)
</script>

<template>
  <label :for="id" class="flex items-center space-x-3 cursor-pointer">
    <span>
      <input
        :id="id"
        v-model="value"
        :disabled="disabled"
        type="checkbox"
        class="peer">
      <fa-icon v-if="value" class="text-primary-500" icon="i-fas-square-check" />
      <fa-icon v-else icon="i-far-square" />
    </span>
    <span class="text-xs">
      <slot></slot>
    </span>
  </label>
</template>

<style lang="postcss" scoped>
input {
  @apply opacity-0 absolute;
}
.peer:focus-visible + svg {
  @apply ring;
}
</style>