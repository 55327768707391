<script setup lang="ts">
// https://vite-plugin-pwa.netlify.app/frameworks/vue.html#periodic-sw-updates
  import { useRegisterSW } from 'virtual:pwa-register/vue'
  import { computed, ref, watch } from 'vue'

  const { needRefresh, updateServiceWorker } = useRegisterSW()

  const updating = ref(false)
  const modal = ref<GModal>()

  const btnUpdateClicked = () => {
    try {
      updating.value = true
      updateServiceWorker()
    } catch (error) {
      window.location.reload()
    } finally {
      updating.value = false
    }
  }

  const LOCALSTORAGE_KEY = 'update_prompt_shown_at'
  const ASK_AGAIN_AFTER = 24 * 60 * 60 * 1000 // 24 hours in miliseconds

  const updateLastUpdatePrompt = () => {
    localStorage.setItem(LOCALSTORAGE_KEY, Date.now().toString())
  }
  const getLastUpdatePrompt = () => {
    return Number(localStorage.getItem(LOCALSTORAGE_KEY))
  }

  const shouldShow = computed(() => {
    if (!needRefresh.value) {
      return false
    }

    return getLastUpdatePrompt() + ASK_AGAIN_AFTER < Date.now()
  })

  watch(shouldShow, newValue => {
    if (newValue) {
      showPrompt()
    }
  })

  const showPrompt = () => {
    modal.value?.open()
  }
  const closePrompt = () => {
    modal.value?.close()
  }
</script>

<template>
  <g-modal ref="modal" title="New update available" @closed="updateLastUpdatePrompt">
    <div class="flex gap-2 justify-center">
      <g-button
        class="btn !text-xs py-2"
        :disabled="updating"
        :class="{'cursor-not-allowed': updating}"
        @click="closePrompt">
        Remind me later
      </g-button>
      <g-button
        class="btn btn-primary !text-xs py-2"
        :disabled="updating"
        :class="{'cursor-not-allowed': updating}"
        @click="btnUpdateClicked">
        <fa-icon v-if="updating" icon="i-fas-circle-notch" class="animate-spin" />
        <template v-else>
          Update
        </template>
      </g-button>
    </div>
  </g-modal>
</template>
