import RouterView from '@/functions/RenderEmptyRouterView'

export default {
  path: '/appointments',
  component: RouterView,
  children: [
    {
      path: '',
      name: 'appointments',
      component: () => import('@/views/Appointment/List/Index.vue'),
    },
    {
      path: '/appointment/new',
      name: 'appointment create',
      component: () => import('@/views/Appointment/Create/PageIndex.vue'),
    },
    {
      path: '/appointment/:id',
      name: 'appointment show',
      props: true,
      component: () => import('@/views/Appointment/Show/Index.vue'),
    },
  ],
}