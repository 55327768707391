<script setup>
  import { computed, ref } from 'vue'

  const showPassword = ref(false)
  const type = computed(() => showPassword.value ? 'text' : 'password')
</script>
<template>
  <div class="relative">
    <g-input :type="type" v-bind="$attrs" />
    <button
      class="absolute right-0 p-2 text-gray-500 top-5"
      tabindex="100"
      type="button"
      @click="showPassword = !showPassword">
      <fa-icon v-if="showPassword" icon="i-far-eye-slash" />
      <fa-icon v-else icon="i-far-eye" />
    </button>
  </div>
</template>

<script>
  export default {
    name: 'InputPassword',
    inheritAttrs: false,
  }
</script>

<style lang="postcss" scoped>
</style>