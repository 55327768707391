<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps<{
    message?: string[] | string,
  }>()

  const text = computed(() => {
    if (!props.message || props.message.length === 0) {
      return null
    }
    if (Array.isArray(props.message) && props.message.length) {
      return props.message[0]
    }
    return props.message
  })
</script>

<template>
  <div v-if="text" class="error-message">
    {{ text }}
  </div>
</template>

<style lang="postcss" scoped>
.error-message {
  @apply my-1 text-xs text-red-500;
}
</style>