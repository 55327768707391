import { defineStore } from 'pinia'
import { type InjectionKey } from 'vue'
import { createStore, type Store, useStore as baseUseStore } from 'vuex'

interface State {
  user: UserInit | null,
  domains: Domain[],
  firebaseToken: string|null,
}

// TODO DECLARE SCHEDULE PRESETS
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    user: null,
    domains: [],
    firebaseToken: null,
  },
  getters: {
    isCoach: (state: State) => {
      return state.user?.role === 'Coach'
    },
    isCompanyManager: (state: State) => {
      return state.user?.role === 'Company Manager'
    },
    isClient: (state: State) => {
      return ['Company Manager', 'User'].includes(state.user?.role ?? '')
    },
    isRegularUser: (state: State) => {
      return state.user?.role === 'User'
    },
  },
  mutations: {
    setUser (state: State, value: UserInit|null) {
      state.user = value
    },
    setDomains (state: State, value: Domain[]) {
      state.domains = value
    },
    setLatestWhoSubmit (state: State, value: UserWhoSubmit) {
      if (state.user) {
        state.user.latest_who_submit = value
      }
    },
    setLatestFToLSubmit (state: State, value: UserFToLSubmit) {
      if (state.user) {
        state.user.latest_f_to_l_submit = value
      }
    },
    setFirebaseToken (state: State, value: string|null) {
      state.firebaseToken = value
    },
  },
  actions: {
    logout ({ commit, state }, { router, api }) {
      api.POST('logout', { firebase_token: state.firebaseToken ?? '' })
      router.replace({ name: 'login' })
      commit('setUser', null)
    },
  },
})

export function useStore () {
  return baseUseStore(key)
}

export const useAppStore = defineStore('App', {
  state: (): State => ({
    domains: [],
    user: null,
    firebaseToken: null,
  }),
  actions: {
    setDomains (domains: Domain[]) {
      this.domains = domains
    },
    getDomain (id: number) {
      return this.domains.find(d => d.id === id)
    },
  },
})
