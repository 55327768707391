<script setup lang="ts">
  import { computed } from 'vue'

  const emit = defineEmits<{
    (e: 'update:modelValue', data: string): void,
    (e: 'submit'): void,
  }>()

  const props = withDefaults(defineProps<{
    modelValue: string,
    placeholder?: string,
  }>(), {
    placeholder: 'Search...',
  })

  const data = computed({
    set: (newValue: string) => {
      emit('update:modelValue', newValue)
    },
    get: () => props.modelValue,
  })
</script>

<template>
  <form class="relative" @submit.prevent="emit('submit')">
    <input
      v-model="data"
      class="w-full py-3 pl-12 text-xs font-medium placeholder-gray-800 bg-gray-100"
      :placeholder="placeholder">
    <div class="absolute top-0 flex items-center h-full px-4">
      <fa-icon icon="i-far-magnifying-glass" />
    </div>
  </form>
</template>