<script setup lang="ts">
  import { type RouteLocationRaw } from 'vue-router'
  import { useRouter } from 'vue-router'

  const router = useRouter()

  defineProps<{title?: string, back?: RouteLocationRaw}>()
</script>

<template>
  <div class="flex items-center mb-4">
    <router-link v-if="back" :to="back" class="p-2 mr-2 lg:hidden">
      <fa-icon icon="i-far-chevron-left" />
    </router-link>
    <button v-else class="p-2 mr-2 lg:hidden" @click="router.go(-1)">
      <fa-icon icon="i-far-chevron-left" />
    </button>
    <div class="inline text-lg font-medium truncate">
      <slot>{{ title }}</slot>
    </div>
  </div>
</template>