import { type App } from 'vue'
import Button from './Button.vue'
import Checkbox from './Checkbox.vue'
import DropdownMenu from './DropdownMenu.vue'
import Empty from './EmptySpace.vue'
import ErrorMsg from './ErrorMessage.vue'
import FaIcon from './FaIcon.vue'
import ImageWrapper from './ImageWrapper.vue'
import Input from './Input.vue'
import Password from './InputPassword.vue'
import UseModal from './modal'
import Pagination from './Pagination.vue'
import Search from './Search.vue'
import Title from './Title.vue'

export default {
  install (Vue: App) {
    Vue.component('GButton', Button)
    Vue.component('GCheckbox', Checkbox)
    Vue.component('GDropdown', DropdownMenu)
    Vue.component('GErrorMsg', ErrorMsg)
    Vue.component('GImg', ImageWrapper)
    Vue.component('GInput', Input)
    Vue.component('GPagination', Pagination)
    Vue.component('GPassword', Password)
    Vue.component('GSearch', Search)
    Vue.component('GTitle', Title)
    Vue.component('GEmpty', Empty)
    Vue.component('FaIcon', FaIcon)
    Vue.use(UseModal)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    GButton: typeof Button,
    GCheckbox: typeof Checkbox,
    GDropdown: typeof DropdownMenu,
    GErrorMsg: typeof ErrorMsg,
    GImg: typeof ImageWrapper,
    GInput: typeof Input,
    GPagination: typeof Pagination,
    GPassword: typeof Password,
    GSearch: typeof Search,
    GTitle: typeof Title,
    GEmpty: typeof Empty,
    FaIcon: typeof FaIcon,
  }
}