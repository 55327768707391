import { type App, ref, watch } from 'vue'
import { useDocumentOverflow, useEscListener } from '@/functions'
import AppModal from './app-modal.vue'
import AppModalContent from './app-modal-content.vue'
import AppModalFooter from './app-modal-footer.vue'
import AppModalForm from './app-modal-form.vue'
import AppModalTitle from './app-modal-title.vue'

export function useModal () {
  const { disableOverflow, reenableOverflow } = useDocumentOverflow()
  const isOpen = ref(false)

  const open = () => {
    isOpen.value = true
  }
  const close = () => {
    isOpen.value = false
  }

  const onEscape = useEscListener(close)

  watch(isOpen, (value) => {
    if (value) {
      disableOverflow()
      window.addEventListener('keydown', onEscape)
    } else {
      reenableOverflow()
      window.removeEventListener('keydown', onEscape)
    }
  })

  return { isOpen, open, close }
}

type Modalable = GModal
export function useAppModal (onModalOpened?: (...args: any[]) => void) {
  const modal = ref<Modalable>()
  // calling open with any arguments will be passed to onModalOpened callback
  const open = (...args: any) => {
    onModalOpened?.(...args)
    modal.value?.open()
  }
  const close = () => {
    modal.value?.close()
  }
  return { modal, open, close }
}

export default {
  install (app: App) {
    app.component('GModal', AppModal)
    app.component('GModalContent', AppModalContent)
    app.component('GModalForm', AppModalForm)
    app.component('GModalFooter', AppModalFooter)
    app.component('GModalTitle', AppModalTitle)
  },
}
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    GModal: typeof AppModal,
    GModalContent: typeof AppModalContent,
    GModalFooter: typeof AppModalFooter,
    GModalForm: typeof AppModalForm,
    GModalTitle: typeof AppModalTitle,
  }
}

declare global {
  type GModal = InstanceType<typeof AppModal>
}
